<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
                 <v-col cols="10"></v-col>
                 <v-col cols="2">
                     <v-btn cols="2" class="success" @click.stop="onDownload">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-col>
             </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start">
                                    <v-select class="mt-5"
                                              :items="customYear"
                                              :label="$t('select_year')"
                                              v-model="yearFromDate"
                                              prepend-icon="event"
                                              dense
                                              outlined></v-select>

                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click.stop="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <v-btn @click.stop="onDownload" small class="success mx-4">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                    <!--<span v-else-if="props.column.field == 'actions'">
            <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
            <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
        </span>-->
                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            },
            items() {
                return [
                    {
                        text: this.$t('income_generation_report'),
                        exact: true,
                        to: '/reportModules/reportIncomeGenerationDashboard'
                    },
                    {
                        text: this.$t('income_generation_report'),
                        exact: true,
                        disabled: true
                    }
                ]
            }
        },
        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                /* this.serverParams.ToDate = date;*/
            },
            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async onDownload() {
                this.snackbar.isLoading = true;
                let param = {
                    FromDate: this.serverParams.FromDate + '-01-01',
                };
                    await axios({
                        url: 'NRBExchange/DownloadIncomeGenerationReportAsync',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'IncomeGenerationReportAsync.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.snackbar.isLoading = false;
                    });
                },

            loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    FromDate: this.serverParams.FromDate + '-01-01',
                };
                axios.post('NRBExchange/GetIncomeGenerationReportAsync', param)
                    .then(({data}) => {
                        this.rows = data
                        this.totalRecords = data.length
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    });


            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.loadItems()
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                columns: [
                    {
                        label: 'form.sector_name',
                        field: 'sectorName'
                    },
                    {
                        label: 'january',
                        field: 'january'
                    },
                    {
                        label: 'february',
                        field: 'february'
                    }, {
                        label: 'march',
                        field: 'march'
                    }, {
                        label: 'april',
                        field: 'april'
                    }, {
                        label: 'may',
                        field: 'may'
                    }, {
                        label: 'june',
                        field: 'june'
                    }, {
                        label: 'july',
                        field: 'july'
                    }, {
                        label: 'august',
                        field: 'august'
                    }, {
                        label:'september',
                        field: 'september'
                    }, {
                        label: 'october',
                        field: 'october'
                    }, {
                        label: 'november',
                        field: 'november'
                    }, {
                        label: 'december',
                        field: 'december'
                    }, {
                        label: 'total',
                        field: 'total'
                    }
                ],
                rows: [],
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    }
                ],
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc",
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
